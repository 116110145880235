import { post, get } from './request'

export default {
    /**
     * 添加围栏
     * @returns {Promise | Promise<unknown>}
     */
    add(params) {
        return post('/fence/createFence', params)
    },
    /**
     * 修改围栏
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    edit(params) {
        return post('/fence/updateFence', params)
    },

    /**
     * 删除围栏
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    remove(params) {
        return post('/fence/deleteFence', params)
    },
    /**
     * 查找围栏列表
     * @param pageNum
     * @param pageSize
     * @returns {Promise | Promise<unknown>}
     */
    findAll(pageNum, pageSize) {
        return get('/fence/findFence?pageNum=' + pageNum + '&pageSize=' + pageSize)
    },
    /**
     * 条件搜索
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    findByCondition(param) {
        return get('/fence/findFenceByFenceName', param)
    },
    /**
     * 获取城市列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    findCitys() {
        return get('/city/findCity')
    },

    /**
     * 查找城市下的围栏
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    findByCity(param) {
        return get('/fence/findLocaoFenceByCity', param)
    },
    /**
 * 创建并绑定临时围栏
 * @returns {Promise | Promise<unknown>}
 */
    createTemporaryFence(params) {
        return post('/fence/createTemporaryFence', params)
    },
    /**
* 查询临时围栏信息
* @returns {Promise | Promise<unknown>}
*/
    findTemporaryFence(params) {
        return post('/fence/findTemporaryFence', params)
    },
}