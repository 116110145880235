import {post,get} from './request'

export default {

    /**
     * 获取历史轨迹
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    loadHisTrace(params){
        return  post('localLoc/getEntityLocLog',params)
    },

    /**
     * 导出轨迹excel
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    downLoadTraceFile(params){
        return  post('localLoc/exportLocLog',params)
    },

    /**
     * 获取实时位置
     * @param entityId
     * @returns {Promise | Promise<unknown>}
     */
    getCurrentPos(entityId,factory){
        return  get('localLoc/getEntityLoc/'+entityId+'/'+factory)
    },

    /**
     * 查询坐标点信息
     * @param lat
     * @param lng
     */
    getPosDetail(lat,lng){
        return  get('localLoc/getLocPOI/'+lat+'/'+lng)
    },

    linear(initPos, targetPos, currentCount, count) {
        let b = initPos, c = targetPos - initPos, t = currentCount,
            d = count;
        return c * t / d + b;
    }
}