//车辆状态格式化
export function isCarStatus(val){
    if(val=='1')
    {
        return '运营';
    }
    else if(val=='2'){
        return '下线';
    }
    else if(val=='3'){
      return '采购';
    }else{
        return '无';
    }
}
//车辆状态格式化
export function isCarType(v){
    let type = ['跑车','SUV','MPV','皮卡','轿车','旅行车'];
    return type[v-1];
}
//车辆运营平台状态
export function isCarOperState(v){
  let type = ['运营','停运','采购'];
  if(v){
    return type[v-1];
  }else{
    return '无';
  }
}
//车辆当前状态
export function isCarCurrentState(v){
  let type = ['确认采购','手续办理','待租','公务用车','已租','维保中','待处置','已处置','应急/失控','车辆调配'];
  if(v){
    return type[v-1];
  }else{
    return '无';
  }
}
//报警类型
export function formatAlarmType(v){
  let type = ['设备报警','入围栏报警','出围栏报警','超时未还车','超时未行驶','在库车辆异动', '出封闭区域报警', '超时离线'];
    return type[v-1];
}
export function formatFactory(type){
    if(type == 1){
      return '四海行';
    }else if(type==2){
      return '艾能';
    }else if(type==3){
      return '自有硬件';
    }
    else if(type==4){
      return '中瑞';
    }
    else if(type==5){
      return '赛格';
    }else{
      return '--';
    }
  }
  export function equipTypeFormat(v){
    if(v == 1){
      return 'OBD';
    }else if(v == 2){
      return '无线';
    }else if(v == 3){
      return '有线';
    }else if(v == 4){
      return '断油电';
    }else if(v == 5){
      return '静默';
    }else{
      return '';
    }
  }
  export function equipTypeFormatSpan(row){
    if(row.entityType == 1){
      return '<span class="etype">OBD</span>';
    }else if(row.entityType == 3){
      return '<span class="etype">有线</span>';
    }else if(row.entityType == 2){
      return '<span class="etype">无线</span>';
    }else if(row.entityType == 4){
      return '<span class="etype">断油电</span>';
    }else if(row.entityType == 5){
      return '<span class="etype">静默</span>';
    }
    else{
      return '<span>--</span>';
    }
  }
  export function equipTypeFormatSpanNO(row){
    if(row.entityType == 1){
      return <span class="etype">OBD</span>
    }else if(row.entityType == 3){
      return <span class="etype">有线</span>
    }else if(row.entityType == 2){
      return <span class="etype">无线</span>
    }else if(row.entityType == 4){
      return <span class="etype">断油电</span>
    }else if(row.entityType == 5){
      return <span class="etype">静默</span>
    }
    else{
      return '<span>--</span>';
    }
  }
  export function signalTypeFormat(v,entityFactory){
    
    if(entityFactory== 1){
      if(v == 0){
        return '北斗GPS';
      }else if(v == 1){
        return 'Wifi定位';
      }else if(v == 16){
        return '基站定位';
      }else{
        return v;
      }
      //return <span >四海行</span>
    }else if(entityFactory == 2){
      return v;
      //return <span >艾能</span>
    }else if(entityFactory == 3){
      return v;
      //return <span>自有硬件</span>
    }else if(entityFactory == 4){
      if(v == 0){
        return 'GPS';
      }else if(v == 1){
        return '基站';
      }else if(v == 2){
        return 'WIFI';
      }else if(v == 3){
        return '北斗';
      }else if(v == 4){
        return 'GPS/北斗混合定位';
      }else{
        return v;
      }
      //定位类型 (int类型 0:GPS，1:基站，2:WIFI，3:北斗，4:GPS/北斗混合定位) (Pktype==1时，该值无效)
      //return <span>中瑞</span>
    }else if(entityFactory == 5){
      if(v == 0){
        return 'GPS';
      }else if(v == 1){
        return '基站';
      }else if(v == 2){
        return 'WIFI';
      }else if(v == 3){
        return '北斗';
      }else if(v == 4){
        return 'GPS/北斗混合定位';
      }else{
        return v;
      }
      //定位类型 (int类型 0:GPS，1:基站，2:WIFI，3:北斗，4:GPS/北斗混合定位) (Pktype==1时，该值无效)
      //return <span>赛格</span>
    }else{
      if(v == 0){
        return 'GPS';
      }else if(v == 1){
        return '基站';
      }else if(v == 2){
        return 'WIFI';
      }else if(v == 3){
        return '北斗';
      }else if(v == 4){
        return 'GPS/北斗混合定位';
      }else{
        return v;
      }
      //return <span>无</span>
    }
  }
  export function manufactoryFormat(entityFactory){
    if(entityFactory== 1){
      return <span >四海行</span>
    }else if(entityFactory == 2){
      return <span >艾能</span>
    }else if(entityFactory == 3){
      return <span>自有硬件</span>
    }else if(entityFactory == 4){
      return <span>中瑞</span>
    }else if(entityFactory == 5){
      return <span>赛格</span>
    }else{
      return <span>无</span>
    }
  }